import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

const BlogPost = ({ data, location }) => {
  const { markdownRemark: post } = data
  const title = `${data.site.siteMetadata.title} – ${post.frontmatter.title}`

  return (
    <Layout location={location}>
      <div>
        <Helmet>
          <title>{title}</title>
          <meta property="og:image" content={post.frontmatter.blurbImage} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={post.frontmatter.blurb} />
        </Helmet>
        <div className='blog'>
          <div className={`blog-art ${post.frontmatter.blogArt}`}>
            <div>
              <div className='outset'>
                <h1>{post.frontmatter.title}</h1>
                <div className="published-on">
                  <time>{post.frontmatter.date}</time>
                </div>
              </div>
            </div>
          </div>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </div>
      </div>
    </Layout>
  )
}

export const postQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        date
        blurb
        blurbImage
        blogArt
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default BlogPost
